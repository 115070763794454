article.type-post
{
  header
  {
    margin-bottom: 1rem;

    h2,
    h1
    {
      display: inline-block;
      margin-left: 2rem;
    }

    a
    {
      color: $color-body;

      &:before
      {
        content: '';
      }
    }
  }
}

@media (max-width: $screen-xs-max) {
  article.type-post header h1,
  article.type-post header h2
  {
    margin-left: 0 !important;
  }
}
