body.home
{
  #container
  {
    display: none;
  }
}

@media (max-width: $screen-xs-max) {
  html,
  body.home
  {
    height: 100%;
  }

  body.home
  {
    background: url('../images/w2h-logo-cut.svg') center center no-repeat;
    background-size: 50% auto;
  }
}
