a.icon-close
{
  margin: 7px -15px 0 0;
  width: 15px;
  height: 15px;
  display: inline-block;
  text-indent: 9999px;
  overflow: hidden;
  background: url('../images/close.svg') 0 0 no-repeat;
  background-size: 15px 15px;

  &:before
  {
    content: '';
  }
}
