.cp-team-list
{
  .cp-team-image
  {
    position: relative;
    overflow: hidden;

    img
    {
      margin: 0;
    }

    &.cp-team-has-cv
    {
      cursor: pointer;
    }
  }

  .cp-show-cv .cp-team-cv
  {
    top: 0;
  }

  .cp-team-cv
  {
    transition: top;
    transition-duration: 350ms;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 0;
    top: -100%;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 8px;
    color: #ffffff;

    p
    {
      margin-bottom: 7px;
    }
  }

  .cp-team-cv-tag
  {
    position: absolute;
    left: 8px;
    top: 8px;
    color: #000;
  }

  .cp-team-position
  {
    float: left;
  }

  .cp-team-email
  {
    float: right;
  }

  article
  {
    margin-bottom: 16px;
  }
}
