// Search form
.search-form {
  @extend .form-inline !optional;
}
.search-form label {
  font-weight: normal;
  @extend .form-group !optional;
}
.search-form .search-field {
  @extend .form-control !optional;
}
.search-form .search-submit {
  @extend .btn !optional;
  @extend .btn-default !optional;
}
