$background: #e74c3c;
$select-color: #000;
$select-background: #c0392b;

.select
{
  text-align: left;
  cursor: pointer;
  display: inline-block;
  position: relative;
  color: $select-color;
  width: 220px;
  height: 2 * $font-size-body + rem;
  max-width: 100%;
}

.select-styled
{
  line-height: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border: 1px solid $select-color;
  padding: 6px;
  &:after
  {
    content: '';
    background: url('../images/chevron.svg') 0 0 no-repeat;
    background-size: 15px 15px;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0.5rem;
    right: 6px;
    transition: all 200ms ease-in;
  }
  &:active, &.active
  {
    &:after
    {
      transform: rotate(180deg);
    }
  }
}

.select-options
{
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 500;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #ffffff;
  border-left: 1px solid $select-color;
  border-right: 1px solid $select-color;
  border-bottom: 1px solid $select-color;
  li
  {
    margin: 0;
    padding: 5px;
    transition: all 100ms ease-in;
    &:hover
    {
      background: #f0f0f0;
    }
    &[rel="hide"]
    {
      display: none;
    }

    &.select-disabled
    {
      color: #bbb;
      cursor: default;

      &:hover
      {
        background-color: #ffffff;
      }
    }
  }
}
