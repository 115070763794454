body#tinymce
{
  font-size: 16px;
  margin: 12px !important;
  width: auto !important;
  height: auto !important;
  overflow: auto !important;
  padding: 15px !important;

  dl.wp-caption
  {
    position: relative;

    dd.wp-caption-dd
    {
      position: absolute;
      left: 5px;
      bottom: 5px;
      line-height: 1;
      padding: 0;
      margin: 0;
    }
  }

  div.row
  {
    padding: 15px 0;
    background-color: #fde2b9;
    border: 1px dashed #000000;

    div[class^='col-'],
    div[class*=' col-']
    {
      min-height: 1.3rem;
      background: #ffffff;
      border-left: 15px solid #fde2b9;
      border-right: 15px solid #fde2b9;
      padding: 0;
    }
  }
}
