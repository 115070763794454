.cp-portfolio-list
{
  .cp-portfolio-img
  {
    position: relative;

    .cp-portfolio-star
    {
      position: absolute;
      left: 10px;
      top: 10px;
    }

    img {
      margin-bottom:0.5rem;
    }
  }

  a:before
  {
    content: '';
  }

  article
  {
    margin-bottom: 16px;
  }
}

.portfolio-filter
{
  margin-top: 12px;
}

@media (max-width: $screen-xs-max) {
  .portfolio-filter
  {
    display: none;
  }

  .cp-portfolio-cats
  {
    display: none;
  }

  .cp-portfolio-list
  {
    h2.entry-title
    {
      margin: 0 0 10px 0 !important;
    }

    .cp-portfolio-img img
    {
      margin-bottom: 10px;
    }
  }
}
@media (min-width: $screen-sm-min) {

  // Alternative without flexbox:
  /*
  // Additional space for filter selects
  body.post-type-archive-cp_portfolio
  {
    .inner-container
    {
      //height: calc(100% - 4.4rem); // 100% height - height of .page-header
    }

    .page-header
    {
      //height: 4.4rem;

      h1
      {
        margin: 3px 0 10px 0;
      }
    }
  }
  */
}
