// Portfolio images that can be clicked to be opened in lightbox
#container {
  .cp-portfolio-single {
    img {
      cursor: pointer;
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9000;
  background-color: rgba(0, 0, 0, 0.85);
  color: #fff;
  // Default: hidden
  display: none;

  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon-close {
    background-image: url('../images/close-inv.svg');
    position: absolute;
    cursor: pointer;
    z-index: 9999;
    margin: 0;

    @media (max-width: 769px) {
      top: 1rem;
      right: 1rem;
    }

    @media (min-width: 770px) {
      top: 4rem;
      right: 4rem;
    }
  }
}

.lightbox-modal {
  .wrapper {
    .slider {
      width: 100%;

      &,
      * {
        height: 100%;
      }

      img {
        display: block;
        /**
         * Make sure, the image never exceeds its parent container, but don't use
         * "object-fit: contain", as this will set the img bounding box to 100% width
         * and height and won't allow the JavaScript code in lightbox.js to get the
         * actual image width (required to calculate the srcset "sizes" attribute).
         */
        height: auto;
        width: auto;
        max-height: 100%;
        max-width: 100%;
      }

      @media (max-width: 769px) {
        .img-wrapper {
          position: relative;

          img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

/* Slick theming */
.slick-slider {
  position: relative;

  .slick-arrow {
    @media (max-width: 769px) {
      display: none !important;
    }

    @media (min-width: 770px) {
      position: absolute;
      top: 50%;
      z-index: 100;
      cursor: pointer;
      width: 30px;
      height: 16px !important;
      background: url('../images/arrow-inv.svg') center top no-repeat;
      background-color: transparent;
      background-size: 30px 16px;
      text-indent: -9999px;
      overflow: hidden;
      border: none;
      outline: 0;

      &.slick-prev {
        left: 0;
        transform: translateY(-50%) rotate(-90deg);
      }

      &.slick-next {
        right: 0;
        transform: translateY(-50%) rotate(90deg);
      }
    }
  }
}
