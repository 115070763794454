@font-face
{
  font-family: 'GT-Pressura';
  src: url('../fonts/GT-Pressura-Regular.eot');
  src: url('../fonts/GT-Pressura-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GT-Pressura-Regular.woff') format('woff'),
  url('../fonts/GT-Pressura-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face
{
  font-family: 'GT-Pressura';
  src: url('../fonts/GT-Pressura-Regular-Italic.eot');
  src: url('../fonts/GT-Pressura-Regular-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GT-Pressura-Regular-Italic.woff') format('woff'),
  url('../fonts/GT-Pressura-Regular-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face
{
  font-family: 'GT-Pressura';
  src: url('../fonts/GT-Pressura-Light.eot');
  src: url('../fonts/GT-Pressura-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GT-Pressura-Light.woff') format('woff'),
  url('../fonts/GT-Pressura-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face
{
  font-family: 'GT-Pressura';
  src: url('../fonts/GT-Pressura-Light-Italic.eot');
  src: url('../fonts/GT-Pressura-Light-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GT-Pressura-Light-Italic.woff') format('woff'),
  url('../fonts/GT-Pressura-Light-Italic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face
{
  font-family: 'GT-Pressura';
  src: url('../fonts/GT-Pressura-Bold.eot');
  src: url('../fonts/GT-Pressura-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GT-Pressura-Bold.woff') format('woff'),
  url('../fonts/GT-Pressura-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face
{
  font-family: 'GT-Pressura';
  src: url('../fonts/GT-Pressura-Bold-Italic.eot');
  src: url('../fonts/GT-Pressura-Bold-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GT-Pressura-Bold-Italic.woff') format('woff'),
  url('../fonts/GT-Pressura-Bold-Italic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

