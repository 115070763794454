.custom-scrollbar
{
  z-index: 99999;
  position: absolute;
  right: -22px;
  top: 0;
  height: 100%;
  width: 30px;

  .custom-scroll-arrow-top,
  .custom-scroll-arrow-bottom
  {
    display: block;
    width: 30px;
    height: 16px;
    background: url('../images/arrow.svg') center top no-repeat;
    background-size: 30px 16px;
    position: absolute;
    right: 0;
    cursor: pointer;
  }

  .custom-scroll-arrow-top
  {
    top: 0;
  }

  .custom-scroll-arrow-bottom
  {
    transform: rotate(180deg);
    bottom: 0;
  }

  // Hide when on top
  &.scrolled-up .custom-scroll-arrow-top
  {
    display: none;
  }

  // Hide when on bottom
  &.scrolled-down .custom-scroll-arrow-bottom
  {
    display: none;
  }

  // Hide when nothing to scroll
  &.no-scrolling
  {
    display: none;
  }
}
