$main-font-size: 16;

@function px2rem($pixelsize) {
  @return $pixelsize / $main-font-size;
}

$font-size-body: px2rem(15);
$font-size-h1: px2rem(19);
$font-size-h2: px2rem(19);
$font-size-h3: px2rem(17);
$font-size-nav: px2rem(32);
$font-size-nav-mobile: px2rem(20);
$font-size-team-h2: px2rem(16);
$font-size-team-p: px2rem(13);
$font-size-portfolio-h2: px2rem(16);
$font-size-portfolio-p: px2rem(13);

html
{
  font-size: $main-font-size + px;
}

body
{
  font-family: 'GT-Pressura', sans-serif;
  font-weight: 300;
  font-size: $font-size-body + rem;
  line-height: 1.33333333333333;
  letter-spacing: 0.025rem;
  color: $color-body;
}

a
{
  &,
  &:visited,
  &:active,
  &:hover
  {
    color: $color-link;
  }
}

main a
{
  &:before
  {
    content: '→ ';
  }

  &.social-link.instagram {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 1;

    &::before {
      content: '';
      margin-right: 0.25rem;

      @extend .icon;
      @extend .icon-instagram;
    }
  }
}

h1
{
  font-weight: 300;
  font-size: $font-size-h1 + rem;
  line-height: 1.33333333333333;
  letter-spacing: 0.025rem;
  margin: 0;
}

h2
{
  font-weight: 300;
  font-size: $font-size-h2 + rem;
  line-height: 1.33333333333333;
  letter-spacing: 0.025rem;
  margin: 0;
}

h3
{
  font-weight: 300;
  font-size: $font-size-h3 + rem;
  line-height: 1.33333333333333;
  letter-spacing: 0.025rem;
  margin: 0;
}

figcaption
{
  line-height: 1;
}

article header time
{
  font-weight: 300;
  font-size: $font-size-h2 + rem;
  line-height: 1.33333333333333;
  letter-spacing: 0.025rem;
}

main ul
{
  list-style: none;
  padding: 0;
  margin: 0;

  li
  {
    &:before
    {
      content: '— '
    }
  }

  a:before
  {
    content: '';
  }
}

.nav-primary a {
  font-weight: 300;
  font-size: $font-size-nav + rem;
  line-height: 1;
  letter-spacing: 0.11rem;
  color: $color-link;
}

.cp-team-list
{
  font-size: $font-size-team-p + rem;
  font-weight: 300;
  line-height: 1.23076923076923;

  h2.entry-title
  {
    font-size: $font-size-team-h2 + rem;
    font-weight: normal;
    line-height: 1.0625;
    margin: 8px 0 0 0;
  }
}

.cp-portfolio-list
{
  font-size: $font-size-portfolio-p + rem;
  font-weight: 300;
  line-height: 1.23076923076923;

  h2.entry-title
  {
    font-size: $font-size-portfolio-h2 + rem;
    font-weight: normal;
    line-height: 1.0625;
    margin: 8px 0 0 0;
  }
}

@media (max-width: $screen-xs-max) {
  html
  {
    font-size: 20px;
  }

  .nav-primary a
  {
    font-size: $font-size-nav-mobile + rem;
  }
}

@media (min-width: $screen-sm-min) {
  html
  {
    font-size: 17px;
  }
}

@media (min-width: $screen-md-min) {
  html
  {
    font-size: 17px;
  }
}

@media (min-width: $screen-lg-min) {
  html
  {
    font-size: 17px;
  }
}
