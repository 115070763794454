@media (min-width: $screen-sm-min) {
  .nav-ext {
    $radius: 3;

    display: none; // Will be animated by JS in /scripts/main.js
    z-index: 100;
    position: absolute;
    right: $body-padding + rem;
    top: $body-padding + rem;
    width: $radius * 2 + rem;
    height: $radius * 2 + rem;

    ul {
      width: 100%;
      height: 100%;
      list-style: none;
      margin: 0;
      padding: 0;
      border-radius: $radius + rem;
      overflow: hidden;
      transform: rotate(30deg);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      li {
        background-color: $color-body;
        display: flex;
        flex-direction: column;

        &:hover {
          background-color: lighten($color-body, 15%);
        }

        a {
          display: block;
          line-height: 1;
          text-transform: uppercase;
          text-align: center;
          color: #ffffff;
          font-weight: bold;
          font-size: px2rem(19) + rem;
          position: relative;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  .has-jobs {
    .nav-ext {
      ul {
        li {
          padding: 0.5rem 0;
          flex: 0 0 49%;

          &.menu-news {
            justify-content: flex-end;
          }
        }
      }
    }
  }

  .has-no-jobs {
    .nav-ext {
      ul {
        li {
          padding: 0;
          flex: 0 0 100%;
          justify-content: center;
        }
      }
    }
  }
}

.has-no-jobs {
  .nav-ext {
    ul {
      li.menu-jobs {
        display: none;
      }
    }
  }
}
