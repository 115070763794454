main
{
  img
  {
    @extend .img-responsive;
    margin-bottom: 28px;
  }

  p,
  ul
  {
    margin-bottom: 1rem;
  }
}

.page-header
{
  border-bottom: none;
}

body.no-js
{
  .close-container
  {
    display: none;
  }
}

figure
{
  position: relative;

  figcaption,
  .wp-caption-text
  {
    padding: 0;
    position: absolute;
    left: 5px;
    bottom: 5px;
  }
}

img {
  -ms-interpolation-mode: bicubic;
  image-rendering: optimizeQuality;
}

@media (max-width: $screen-xs-max) {

  body
  {
    padding-top: 15px;
  }

  #w2h-mobile-container
  {
    margin-bottom: 20px;
    padding-left: 15px;
  }

  body:not(.home) #w2h-mobile-logo
  {
    width: 20%;
    float: left;
  }
  body.home #w2h-mobile-logo {
    display:none;
  }

  .page-header
  {
    margin-top: 0;

    h1
    {
      text-align: left;
    }

    .close
    {
      display: none;
    }
  }

  .nav-primary
  {
    background: #ffffff;
    padding-bottom: 15px;

    li
    {
      margin-bottom: 10px;
    }
  }

  .navbar-toggle
  {
    display: block;
    float: right;
    width: 50px;
    height: 50px;
    border: none;
    background: url('../images/burger.png') 0 0 no-repeat;
    background-size: 50px 50px;
    margin-right: 15px;
    text-indent: -9999px;
  }
}

@media (min-width: $screen-sm-min) {

  html
  {
    height: 100%;
  }

  body
  {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    padding: $body-padding + rem;

    &.ajax-loading
    {
      cursor: progress !important;
    }
  }

  #w2h-logo-container
  {
    display: block;
    position: absolute;
    height: 50%;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 50;
  }

  #w2h-logo
  {
    height: 100%;
    max-width: 100%;
    display: block;
  }

  // Hide logo while container is open
  body.open-container #w2h-logo-container
  {
    display: none
  }

  .nav-primary
  {
    ul
    {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li:before
    {
      content: '';
    }

    a
    {
      z-index: 100;
      position: absolute;
      text-align: center;
      display: block;
      text-transform: uppercase;

      &,
      &:hover,
      &:active,
      &:visited
      {
        text-decoration: none !important;
        outline: none !important;
      }
    }

    li.active a
    {
      font-weight: bold;
    }

    $border-distance: 1.3;

    .nav-main-portfolio a
    {
      bottom: $border-distance + rem;
      left: 50%;
      width: 20rem;
      margin-left: -10rem;
      cursor: url('../images/cursor-s.png'), pointer;
    }

    .nav-main-team a
    {
      top: 50%;
      margin-top: -0.5 * $font-size-nav + rem;
      right: $border-distance - 8 + $font-size-nav / 2 + rem;
      transform: rotate(-90deg);
      width: 16rem;
      cursor: url('../images/cursor-e.png'), pointer;
    }

    .nav-main-contact a
    {
      top: 50%;
      margin-top: -0.5 * $font-size-nav + rem;
      left: $border-distance - 8 + $font-size-nav / 2 + rem;
      transform: rotate(-90deg);
      width: 16rem;
      cursor: url('../images/cursor-w.png'), pointer;
    }

    .nav-main-about a
    {
      top: $border-distance + rem;
      left: 50%;
      width: 20rem;
      margin-left: -10rem;
      cursor: url('../images/cursor-n.png'), pointer;
    }

    .nav-main-clients
    {
      a
      {
        text-indent: -999px;
        display: block;
        background: url('../images/lock.svg') center center no-repeat;
        $icon-size: 1.3rem;
        width: $icon-size;
        height: $icon-size;
        background-size: $icon-size $icon-size;
        left: $border-distance + rem;
        bottom: $border-distance + rem
      }

      &.active a
      {
        background: url('../images/lock-open.svg') center center no-repeat;
      }
    }
  }

  .container
  {
    z-index: 400;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background-color: #ffffff;
    // Padding required for custom scrollbar, extending outside the container
    max-width: 1260px;
    padding: 0 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
    -ms-flex-flow: column;
    flex-flow: column;
  }

  .inner-container
  {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    position: relative;
    width: 100%;
    // Alternative without flexbox:
    //height: calc(100% - 2rem); // 100% height - height of .page-header
  }

  main
  {
    position: absolute; // must be absolute for flexbox, otherwise relative
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 0 15px 15px 15px;
  }

  .page-header
  {
    // Alternative without flexbox:
    //height: 2rem;
    padding: 5px 0 13px;
    margin: 0;

    .download-file
    {
      padding: 2px 0 0 30px;

      a:after
      {
        content: ' ↓';
        display: inline-block;
        margin-top: 3px;
      }
    }

    h1
    {
      margin: 0;
    }
  }

  .modal-cover
  {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 200;
    display: none;
  }
}

@media (min-width: $screen-md-min) {
}

@media (min-width: $screen-lg-min) {
}
