.icon {
  display: inline-block;
  text-indent: -900px;
  overflow: hidden;
  width: 1.25em;
  height: 1.25em;
  padding: 0;
  background-color: transparent;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border: 0;
}

$icons: instagram;

@each $icon in $icons {
  .icon-#{$icon} {
    background-image: url('../images/#{$icon}.svg');
  }
}
