// On iPad
html.isIpad
{
  // Show default scrollbar
  main
  {
    overflow-x: hidden !important;
    overflow-y: scroll !important;
    -webkit-overflow-scrolling: touch !important;
  }

  .custom-scrollbar
  {
    display: none !important;
  }
}
